import React, { useRef } from "react";
import { Link } from "react-router-dom";
import LogoCarousel from './LogoCarousel';
import { HashLink } from 'react-router-hash-link';


const Banner = () => {
 
    return (
        <>
            <div className="home-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 order-sm-2 order-xs-2 order-md-1">
                            <div className="banner-information">
                                <img src="images/pumpnet-logo.webp" />
                                <div className="clearfix"></div>
                                <p>iPUMPNET is a web-based solution that allows water utilities to improve the performance and energy efficiency of their pumping stations through real-time monitoring, control, and diagnostic tools. It also enables remote management of the pumps, motors, and related assets of the pumping stations, resulting in increased operational efficiency.</p>
                                <p>iPUMPNET uses advanced adaptive modeling, including deep learning and machine learning algorithms, to optimize the Life Cycle Cost (LCC) of the pumping sets of the Utilities.</p>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 border-radius order-xs-1 order-sm-1 text-center  order-md-2">
                            <img className="img-fluid" src="images/pumpnet-installation.webp" />
                        </div>
                    </div>
                </div>
                
                
            </div>
        </>
    )
}

export default Banner;