import React, { useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Header = () => {

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };

    });

    const isSticky = (e) => {
        //  const header = document.querySelector('.main_header');
        //  const topbar = document.querySelector('.top-bar');
        //  const scrollTop = window.scrollY;
        //  const topbarTop = window.scrollY;
        //  scrollTop >= 80 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
        //  topbarTop >= 1 ? topbar.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const [open, setOpen] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [openn, setOpenn] = useState();
    const [openMedia, setOpenMedia] = useState();
    const [chapter, setChapter] = useState(false);

    useEffect(() => {
        if (!chapter) {
            setOpenn(false);
            setOpenMedia(false);
            setMobile(false);
        }
    }, [chapter])

    return (
        <>
            <div className="main_header">
                <div id="desktop-menu" className="container-fluid">
                    <Navbar expand="lg">
                        <Link to="/"><img src="images/logo-update.webp" /></Link>

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto align-items-center">
                                <li><Link to="/">Home</Link></li>
                                <li className="mob-menu"><Link to="/about">About us</Link></li>
                                <li><Link to="/ipumpnet"><img src="images/ipumpnet.webp" /></Link></li>
                                <li><Link to="/parameters">Parameters</Link></li>
                                <li className="mob-menu"><Link to="/knowledge-base">Knowledge base</Link></li>
                                <li className="mob-menu"><Link to="/credentials">Credentials</Link></li>
                                <li className="mob-menu"><Link to="/team">Team</Link></li>
                                <li><Link to="/projects">Projects</Link></li>
                                <li><Link to="/request-demo">Request a Demo</Link></li>
                                <li className="mob-menu"><Link to="/contact-us">Contact</Link></li>
                            </Nav>
                        </Navbar.Collapse>

                    </Navbar>
                </div>

                <div id="mobile-menu" className="container-fluid">
                    <Navbar expand="lg">
                        <Link to="/"><img src="images/logo-update.webp" /></Link>
                        <button onClick={() => setMobile(!mobile)} className="phone-toggle">
                            {mobile ? <i className="far fa-times"></i> : <i className="far fa-bars"></i>}
                        </button>

                        {mobile ? <>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto align-items-center">
                                    <li onClick={() => setMobile(!mobile)}><Link to="/">Home</Link></li>
                                    <li onClick={() => setMobile(!mobile)} className="mob-menu"><Link to="/about">About us</Link></li>
                                    <li onClick={() => setMobile(!mobile)}><Link to="/ipumpnet"><img src="images/ipumpnet.webp" /></Link></li>
                                    <li onClick={() => setMobile(!mobile)}><Link to="/parameters">Parameters</Link></li>
                                    <li className="knowledge mob-menu"><a onClick={() => setOpenn(!openn)}>Knowledge base</a>
                                        {openn && <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item" to="/pumpacademy-ipumpnet" onClick={() => setMobile(!mobile)}>Pump Academy - iPUMPNET</Link></li>
                                            <li><Link className="dropdown-item" to="/indicative-major-pump" onClick={() => setMobile(!mobile)}>Chapter - Indicative major pump failure modes</Link>

                                                <div className="right-span" onClick={() => setChapter(!chapter)}>{chapter ? <i className="fal fa-minus"></i> : <i className="fal fa-plus"></i>}</div>
                                                {chapter &&
                                                    <ul className="chapter-drop">
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-excessive-power-consumption' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Excessive Power Consumption</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-cavitation' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Cavitation</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-bearing-failure' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Bearing Failure</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-excessive-vibration' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Excessive Vibration</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-less-flow-yield-than-expected' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Less Flow Yield than Expected</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-pump-operating-without-discharge' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Pump Operating without Discharge</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-leakage' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Leakage</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-seal-failure' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Seal Failure</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-pumps-overheats' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Pumps Overheats</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-corrosion' onClick={() => setChapter(!chapter)}>Pump Failure Modes  - Corrosion</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-lubrication-failure' onClick={() => setChapter(!chapter)}>Pump Failure Modes  - Lubrication Failure</Link></li>
                                                        <li onClick={() => setMobile(!mobile)}><Link to='/pump-failure-modes-imbalanced-or-damaged-shaft' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Imbalanced or damaged shaft</Link></li>
                                                    </ul>
                                                }
                                            </li>
                                            <li onClick={() => setMobile(!mobile)}><Link className="dropdown-item" to="/sub-optimal">Chapter - sub-optimal pump performance</Link></li>
                                            <li onClick={() => setMobile(!mobile)}><Link className="dropdown-item" to="/various-head-terms">Chapter - Various Head Terms</Link></li>
                                            <li onClick={() => setMobile(!mobile)}><Link className="dropdown-item" to="/guidelines-for-proper-sump">Guidelines for proper sump / reservoir design & <br />suction pipe arrangement</Link></li>
                                        </ul>
                                        }
                                    </li>
                                    <li onClick={() => setMobile(!mobile)} className="mob-menu"><Link to="/credentials">Credentials</Link></li>
                                    <li onClick={() => setMobile(!mobile)} className="mob-menu"><Link to="/team">Team</Link></li>
                                    <li onClick={() => setMobile(!mobile)}><Link to="/request-demo">Request a Demo</Link></li>
                                    <li onClick={() => setMobile(!mobile)}><Link to="/projects">Projects</Link></li>
                                    {/* <li onClick={() => setMobile(!mobile)}><Link to="/media">Media</Link></li> */}

                                    <li className="media mob-menu"><a onClick={() => setOpenMedia(!openMedia)}>Media</a>
                                        {openMedia && <ul className="dropdown-menu">
                                            <li onClick={() => setMobile(!mobile)}><Link className="dropdown-item" to="/Media">News</Link></li>
                                            <li onClick={() => setMobile(!mobile)}><Link className="dropdown-item" to="/Press">Press Release</Link></li>
                                            {/* <li onClick={() => setMobile(!mobile)}><Link className="dropdown-item" to="/Blog">Blog</Link></li> */}
                                        </ul>
                                        }
                                    </li>

                                    <li onClick={() => setMobile(!mobile)}><Link to="/download">Download</Link></li>
                                    <li onClick={() => setMobile(!mobile)}><Link to="/careers">Careers</Link></li>
                                    <li onClick={() => setMobile(!mobile)} className="mob-menu"><Link to="/contact-us">Contact</Link></li>
                                </Nav>
                            </Navbar.Collapse>
                        </> : ''}

                    </Navbar>
                </div>
            </div>
        </>
    )
}

export default Header;
